// Fancybox > mode galerie
function fancyboxGalerie(state) {

    var matchMediaPadding,
        maxHeight;

    if (storage) {
        var stateLS = localStorage.setItem("stateLS", 'false');
    }

    function displayInfos() {
        $('.fancybox-wrap-title').addClass('display');
        $('.fancybox-more-infos img').attr('src', pathImgFancybox + 'infos-close.png');
        $('.fancybox-more-infos img').attr('alt', l10n['lessInformation']);
        if (storage) {
            stateLS = localStorage.setItem("stateLS", 'true');
        }
    }

    function HideInfos() {
        $('.fancybox-wrap-title').removeClass('display');
        $('.fancybox-more-infos img').attr('src', pathImgFancybox + 'infos-open.png');
        $('.fancybox-more-infos img').attr('alt', l10n['moreInformation']);
        if (storage) {
            stateLS = localStorage.setItem("stateLS", 'false');
        }
    }

    if (state === 'mediaDesktop') {
        matchMediaPadding = 15;
        maxHeight = '82%';
    } else if ((state === 'mediaSmartphone') || (state === 'mediaTablet')) {
        matchMediaPadding = 0;
        maxHeight = '100%';
    }
    if ($('.fancyboxGalerie').length > 0) {
        $('.fancyboxGalerie').click(function () {
            // Remove data-fancybox-group attribute to the sentinel
            $('.classical-gallery .slider-galerie .cycle-sentinel a').removeAttr('data-fancybox-group');
            // Get the clicked link
            lienFocus = this;
        }).fancybox({
            tpl:         {
                wrap:     '<div class="fancybox-wrap fancybox-galerie" tabindex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>',
                //closeBtn : '<a title="' + l10n['closeModalWindow'] + '" class="fancybox-item fancybox-close" href="javascript:;"><span>' + l10n['close'] + '</span> <img src="'+pathImgFancybox+'close-mobile.png" alt="" /></a>',
                closeBtn: '<div class="fancybox-close-wrap"><button type="button" title="' + l10n['closeModalWindow'] + '" class="fancybox-item fancybox-close" ><span class="hover">' + l10n['close'] + '</span> <img src="' + pathImgFancybox + 'close-mobile.png" alt="" /></button></div>',
                //next     : '<a title="' + l10n['nextImage'] + '" class="fancybox-nav fancybox-next" href="javascript:;"><span>' + l10n['next'] + '</span> <img src="'+pathImgFancybox+'suivant-mobile.png" alt="" /></a>',
                next:     '<button type="button" title="' + l10n['nextImage'] + '" class="fancybox-nav fancybox-next"><span class="hover">' + l10n['next'] + '</span> <img src="' + pathImgFancybox + 'suivant-mobile.png" alt="" /></button>',
                //prev     : '<a title="' + l10n['prevImage'] + '" class="fancybox-nav fancybox-prev" href="javascript:;"><span>' + l10n['prev'] + '</span> <img src="'+pathImgFancybox+'precedent-mobile.png" alt="" /></a>'
                prev:     '<button type="button" title="' + l10n['prevImage'] + '" class="fancybox-nav fancybox-prev"><span class="hover">' + l10n['prev'] + '</span> <img src="' + pathImgFancybox + 'precedent-mobile.png" alt="" /></button>'
            },
            width:       '100',
            maxHeight:   maxHeight,
            padding:     matchMediaPadding,
            margin:      0,
            // Remove effects
            openEffect:  'none',
            closeEffect: 'none',
            nextEffect:  'none',
            prevEffect:  'none',
            autoPlay:    false,
            // Change title position
            helpers:     {
                title:   {
                    type: 'outside'
                },
                buttons: {
                    tpl: '<div id="fancybox-buttons"><button class="btnPlay button-reset"><img src="' + pathImgFancybox + 'play.png" alt=""> <span class="hover">' + l10n['startCarrousel'] + '</span></button></div>'
                }
            },
            //nextClick: false,
            keys:        {
                next: {
                    //13 : 'left', // enter . Supprime l'action de la touche entrer pour l'accessibilité.
                    34: 'up',   // page down
                    39: 'left', // right arrow
                    40: 'up'    // down arrow
                }
            },
            // Rend inactif les liens sous la popin
            // All links under the popin become incative
            beforeLoad:  function () {
                $('*[tabindex=0]').attr('tabindex', -1).attr('datatabindex', 0);
                $('a, input, button, select, audio, select').attr('tabindex', -1);
            },
            // focus on active link
            afterClose:  function () {
                lienFocus.focus();
                $('*[datatabindex=0]').attr('tabindex', 0);
                $('a, button, input, select, audio, select').attr('tabindex', 0);
            },
            // Manage pager for gallery
            afterLoad:   function () {
                var caption = this.element.next('figcaption').text();
                this.title = caption;

                if (this.title) {
                    this.title = '<p class="numberOfFancybox">' + l10n['Picture'] + ' ' + (this.index + 1) + ' ' + l10n['on'] + ' ' + this.group.length + (this.title ? '</p><p class="captionFancybox">' + this.title : '</p>');
                } else {
                    this.title = '<p class="numberOfFancybox">' + l10n['Picture'] + ' ' + (this.index + 1) + ' ' + l10n['on'] + ' ' + this.group.length + (this.title ? '</p>' + this.title : '');
                }
            },
            beforeShow:  function () {
                $('.fancybox-overlay a, .fancybox-overlay button, .fancybox-overlay input, .fancybox-overlay select, .fancybox-overlay audio, .fancybox-overlay video').attr('tabindex', 0);
                var alt = this.element.find('img').attr('data-alt');
                this.inner.find('img').attr('alt', alt);
                this.alt = alt;

                console.log(this);


            },
            afterShow:   function () {
                // Focus on the popin
                $('.fancybox-inner').attr('tabindex', 1).focus();

                $('.fancybox-overlay').addClass('fancybox-overlay-gallery');

                $('.fancybox-title').wrap('<div class="fancybox-wrap-title"></div>');
                $('.fancybox-wrap-title').prepend('<button type="button" class="fancybox-more-infos"><img src="' + pathImgFancybox + 'infos-open.png" alt="' + l10n['moreInformation'] + '"></button>');
                var fancyboxMoreInfos = $('.fancybox-more-infos');

                $('.fancybox-next').on('click', function () {
                    if (localStorage.getItem('stateLS') === 'true') {
                        displayInfos();
                    }
                });

                fancyboxMoreInfos.on('click', function () {
                    if (localStorage.getItem('stateLS') === 'false') {
                        displayInfos();
                    } else if (localStorage.getItem('stateLS')) {
                        HideInfos();
                    }
                });

                $('.fancybox-wrap').swipe({
                    swipe: function (event, direction) {
                        if (direction === 'left' || direction === 'up') {
                            $.fancybox.next(direction);
                        } else {
                            $.fancybox.prev(direction);
                        }
                    }
                });
            },
            onPlayStart: function () {
                $("#fancybox-buttons.clone").find(".btnPlay").addClass('btnPlayOn');
                $("body #fancybox-buttons").find(".btnPlay").html('<img src="' + pathImgFancybox + 'pause.png" alt=""> <span class="hover">' + l10n['stopCarrousel'] + '</span>');
            },
            onPlayEnd:   function () {
                $("#fancybox-buttons.clone").find(".btnPlay").addClass('btnPlayOff');
                $("body #fancybox-buttons").find(".btnPlay").html('<img src="' + pathImgFancybox + 'play.png" alt=""> <span class="hover">' + l10n['startCarrousel'] + '</span>');
            },
            onUpdate:    function () {
                if (localStorage.getItem('stateLS') === 'true') {
                    displayInfos();
                }
            }
        });
    }
}
