;(function ($, window, document) {

    var $this;
    var content = {};

    var to;
    var settings = {
        // Defaults
        offsetElem: '',
        storyElem: '.story',
        barsContainer: 'anchor-bar__list',
        barClass: 'anchor-bar__item',
        headlineSelector: '.side-anchor',
        mobileSelect: 'anchor-bar__select',
        topText: ''
    };


    $.fn.tocProgress = function (options) {

        //reference to the element that called the plugin
        $this = $(this);
        settings = $.extend(settings, options);


        // First hide the table of contents
        $this.hide();

        $(window).on('resize', function (event) {
            // Wait 500ms after resizing window to reset things

            if (to != null) {
                clearTimeout(to);
            }
            to = window.setTimeout(function () {
                delete content;
                initPlugin();
                content = thestories();
                makeBarsClickable();
                makeSelectClickable();
                $this.fadeIn(1000);
            }, 500);

        });

        $(window).on('load', function () {

            // $this.prepend('<div class="toc-title">' + $('h1').first().text() + '</div>');
            $this.append('<div id="' + settings.barsContainer + '"></div>');

            setupHTML(settings.storyElem);
            initPlugin();
            content = thestories();
            makeBarsClickable();
            makeSelectClickable();
            $(window).scroll(function (event) {
                calcProgress();
            });
            $this.fadeIn(1000);
            return this;

        });
    }

    /* Helpers */
    function Story() {
    }

    function numStories() {
        var i = 0;
        $('[data-index]').each(function () {
            i++;
        });
        return i;
    }

    var getHeadline = function (i, elem) {
        return $('[data-index=' + i + '] ' + elem + '').first().text();
    }

    var setupHTML = function (storyElem) {
        $(storyElem).each(function (index, el) {
            $(this).attr({'data-index': index});
        });

    }

    var initProgressBars = function (barsContainer, barClass, headlineSelector, topText) {

        var output = "";
        for (var i = 0; i < numStories(); i++) {
            output += '<button class="' + barClass + '" data-story="' + i + '">';
            output += '<p>' + getHeadline(i, headlineSelector) + '</p>';
            output += '<div class="toc-bar"></div>';
            output += '</button>';
        }

        $('#' + barsContainer).append(output);
        $('.' + barClass).css('cursor', 'pointer');

        // addTopLink(topText); //

    }

    function initPlugin() {
        var container = $('#' + settings.barsContainer),
            windowWidth = $(window).width();

        if (windowWidth < 1280 && container.length) {
            container.find('.' + settings.barClass).remove();
            if( !$('.' + settings.mobileSelect).length ) {
                initSelect(
                    settings.barsContainer,
                    settings.headlineSelector
                );
            }
        } else {
            container.find('.' + settings.mobileSelect).remove();
            container.find('label').remove();
            if( !$('.' + settings.barClass).length ) {
                initProgressBars(
                    settings.barsContainer,
                    settings.barClass,
                    settings.headlineSelector,
                    settings.topText
                );

            }
        }
    }

    var initSelect = function (barsContainer, headlineSelector) {
        var output = "";
        for (var i = 0; i < numStories(); i++) {
            output += '<option>';
            output += getHeadline(i, headlineSelector);
            output += '</option>';
        }

        $('#' + barsContainer).append('\
          <label for="toc-progress" class="ghost">Progression :</label> \
          <select class="' + settings.mobileSelect + '" id="toc-progress">' + output + '</select>\
      ');
    }

    function makeSelectClickable() {
        $('.' + settings.mobileSelect).each(function () {
            var $select = $(this);

            $select.on('change', function () {
                var scrollTopValue = content[$select[0].selectedIndex].top + 2;
                $('body,html').animate({
                    'scrollTop': scrollTopValue
                });
            });
        });
    }

    function addTopLink(wording) {

        $this.append('<div class="toc-top">' + wording + '</div>');
        $('.toc-top').css('cursor', 'pointer');
        $('.toc-top').on('click', function (event) {
            $('body, html').animate({'scrollTop': 0});
        });
    }

    function makeBarsClickable() {
        $("[data-story]").each(function (index, el) {
            var scrollTopValue = content[index].top + 2;
            // console.log(content[index].top);
            $(this).off('click'); // Remove any click events before assigning another
            $(this).on('click', function () {
                $('body, html').animate({'scrollTop': scrollTopValue});
            });
        });

    }

    function getNavContainerHeight(offsetElem) {
        if (offsetElem) {
            return $(offsetElem).height();
        } else {
            return 0;
        }
    }

    function thestories() {

        var navHeight = getNavContainerHeight(settings.offsetElem);
        var numberStories = numStories();

        $('[data-index]').each(function (index, el) {
            content[index] = new Story();
            content[index].index = index;
            content[index].height = $(this).height() - navHeight;
            content[index].top = $(this).offset().top - navHeight;
            content[index].bottom = $(this).position().top + $(this).outerHeight(true) - navHeight;
        });

        return content;

    }

    function calcProgress() {
        var scrollTop = $(window).scrollTop();
        var temp;
        var width;

        if (content != null) {
            $.each(content, function (index, story) {
                temp = scrollTop - story.top;
                width = temp / story.height * 100;
                setBarWidth(story.index, width);
            });

        }
    }

    function setBarWidth(index, width, color) {

        var elem = $("[data-story=" + index + "]");
        var bar = $("[data-story=" + index + "] .toc-bar");
        // user has scrolled passed
        if (width > 100) {
            // elem.css('font-weight', 'normal');
            bar.css({
                width: "100%",
                background: color
            });
            // user is before the story
        } else if (width < 0) {
            // elem.css('font-weight', 'normal');
            elem.removeClass('is-current');
            bar.css({
                width: "0%",
            });
            // user is on the story
        } else {
            // elem.css({'font-weight': 'bold'});
            elem.addClass('is-current');
            bar.css({
                width: width + "%",
                background: color
            });
        }

    }

})(jQuery, window, document);
