$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';

    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit:  767
        }, {
            label: 'tablet',
            enter: 768,
            exit:  1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit:  10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter:      function () {
                myInitFuncSmartphone();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter:      function () {
                myInitFuncTablet();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter:      function () {
                myInitFuncDesktop();

            },
            exit:       function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        //carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        //carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        menuMain1State(mediaSmartphone); // 190_stratis_menu-main-1.js
    }

    function myInitFuncTablet() {
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        //carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        //carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        menuMain1State(mediaTablet); // 190_stratis_menu-main-1.js
    }

    function myInitFuncDesktop() {
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        //carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        //carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        menuMain1State(mediaDesktop); // 190_stratis_menu-main-1.js
    }

    // Generic functions
    preloadImages(); // 170_stratis_preload_images.js
    objectFitImages(); // 175_lib_ofi.min.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    //fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    //enableTimePicker(); // 310_stratis_datetimepicker.js To remove
    formValidation(); // 320_stratis_form_validation.js
    //infiniteScroll(); // 350_stratis_infinite_scroll.js
    //showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    cookiesManagement(); // 360_stratis_cookies_management.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    customTabs(); // 405_stratis_tabs.js
    autocomplete(); // 410_autocomplete.js

    // tocProgress();
    //
    // function stickySidebar() {
    //     var sidebar = $('.sidebar'),
    //         sidebarHeight = sidebar.height();
    //
    //     sidebar.css('height', sidebarHeight);
    //
    //     if ($(window).width() < 1280) {
    //         sidebar.css('height', 'auto');
    //         sidebar.trigger('sticky_kit:detach');
    //     } else {
    //         sidebar.stick_in_parent({
    //             offset_top: 20
    //         });
    //     }
    // }
    //
    // function tocProgress() {
    //     createStructure();
    //     $('#anchor-bar').tocProgress();
    //     setTimeout(stickySidebar, 2000);
    //
    //     function createStructure() {
    //         // if (!$('.section-main__content .rte .subpages-menu').length) {
    //         if ($('.section-main__content .rte h2, .section-main__content .section-in-content h2').not('.ddm .rte h2, .videos h2').length) {
    //             if ($('.section-main__content .rte h2').not('.ddm .rte h2').length) {
    //                 $('.sidebar').prepend('<div id="anchor-bar"><h2 class="sidebar-title">' + l10n['browseThisPage'] + '</h2></div>');
    //                 $('.section-main__content .rte h2, .section-main__content .section-in-content h2').not('.ddm .rte h2, .videos h2')
    //                     .addClass('side-anchor')
    //                     .each(function () {
    //                         $(this)
    //                             .nextUntil('.side-anchor')
    //                             .addBack()
    //                             .wrapAll('<div class="story">');
    //                     });
    //             }
    //         }
    //     }
    // }
    //
    // $(window).on('resize', function () {
    //     stickySidebar();
    // });

    // TocProgressBar
    tocProgress();

    function tocProgress() {
        createStructure();
        $('#anchor-bar').tocProgress();
        // stickySidebar();
        setTimeout(stickySidebar, 4000);

        function createStructure() {
            // if (!$('.section-main__content .rte .subpages-menu').length) {
            if ($('.section-main__content .rte h2, .section-main__content .section-in-content h2').not('.ddm .rte h2, .classical-gallery h2, .videos h2').length) {
                $('.sidebar').prepend('<div id="anchor-bar" class="anchor-bar"><h2 class="sidebar__title">' + l10n['browseThisPage'] + '</h2></div>');
                $('.section-main__content .rte h2, .section-main__content .section-in-content h2').not('.ddm .rte h2, .classical-gallery h2, .videos h2')
                    .addClass('side-anchor')
                    .each(function() {
                        $(this)
                            .nextUntil('.side-anchor')
                            .addBack()
                            .wrapAll('<div class="story">');
                    });
            }
            // }
        }
    }

    // Hero carousel on home page
    $('.hero-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
        fade: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    // autoplay: false,
                    arrows: false,
                    dots: false,
                    draggable: true
                }
            }
        ]
    });

    $('.hero-carousel-controls__item--pause').on('click', function() {
        $('.hero-carousel').slick('slickPause');
        // $('.hero-carousel').find('.slick-current').find('video')[0].pause();
        var video =  $('.hero-carousel').find('.slick-current').find('video');
        if(video.length) {
            video[0].pause();
        }
    });

    $('.hero-carousel-controls__item--play').on('click', function() {
        $('.hero-carousel').slick('slickPlay');
        // $('.hero-carousel').find('.slick-current').find('video')[0].play();
        var video =  $('.hero-carousel').find('.slick-current').find('video');
        if(video.length) {
            video[0].play();
        }
    });

    $('.hero-carousel .slick-arrow').on('click', function() {
        $('.hero-carousel').slick('slickPause');
    });

    $('.hero-carousel .slick-dots button').on('click', function() {
        $('.hero-carousel').slick('slickPause');
    });

    $('.hero-carousel').on('afterChange', function(slick) {
        var video =  $(this).find('.slick-current').find('video');
        if(video.length) {
            video[0].play();
            $(this).slick('slickPause');
        }
    });

    $('.hero-carousel').on('beforeChange', function(slick) {
        var video =  $(this).find('.slick-current').find('video');
        if(video.length) {
            video[0].pause();
        }
    });


    // Acces rapides on home page
    $('.home-rapides-slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    draggable: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    draggable: true
                }
            }
        ]
    });


    // Offer tabs control slider on home page
    $('.offer-tabs__btn-wrapper').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    draggable: true,
                    infinite: true
                }
            }
        ]
    });


    // Mobile tabs slider on home page
    $('.offer-tabs__btn-wrapper').on('swipe', function(event, slick, direction) {
        if(direction) {
            $('.offer-tabs .slick-current a').trigger('click');
        }
    });

    $('.offer-tabs__btn-wrapper .slick-next').on('click', function() {
        $(this).parent().find('.slick-current > a').trigger('click');
    });

    $('.offer-tabs__btn-wrapper .slick-prev').on('click', function() {
        $(this).parent().find('.slick-current > a').trigger('click');
    });


    // Cut button list RSS to page title
    var cutElement = $('.js-cut-to-title');
    var pasteElement = $('.page-title');

    if (cutElement.length) {
        pasteElement.wrap('<div class="page-title-wrapper"></div>');
        pasteElement.parent().append(cutElement);
    }


    // Popup video in fancybox
    $(".fancybox-video").fancybox({
        helpers: {
            media: {}
        },
        beforeLoad:  function () {
            $('*[tabindex=0]').attr('tabindex', -1).attr('datatabindex', 0);
            $('a, input, button, select, audio, select').attr('tabindex', -1);
        },
        afterClose:  function () {
            $('*[datatabindex=0]').attr('tabindex', 0);
            $('a, button, input, select, audio, select').attr('tabindex', 0);
        }
    });
});


// TocProgressBar
function stickySidebar() {
    var sidebar = $('.sidebar');
    // var  sidebarHeight = sidebar.height();

        // sidebar.css('height', sidebarHeight);

    if ($(window).width() < 1280) {
        // sidebar.css('height', 'auto');
        sidebar.trigger('sticky_kit:detach');
    } else {
        sidebar.stick_in_parent({
            offset_top: 20
        });
    }
}
$(window).on("resize", function () {
    stickySidebar();
});


$(window).on("load", function () {

    // Socials tiles on home page
    $('.social-list').masonry({
        itemSelector: '.social-list__item',
        columnWidth: '.social-list__item',
        percentPosition: true
    });

});

accessTooltipState(); // 400_stratis_access-tooltip.js
