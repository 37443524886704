$(document).ready(function () {
  var api = $('.map__search .container').jScrollPane({
    showArrows: true,
    autoReinitialise: true,
    animateScroll: true
  }).data('jsp');

  $('.map__search__arrow.scroll-up').click(function() {
      api.scrollByY(-50);
      return false;
  });

  $(".filters__reset").on('click', function () {
    // $('.filters__hide').trigger('click');
      $('.filters__toggle').text('Afficher les résultats');
  });

  $('.map__search__arrow.scroll-down').click(function() {
    api.scrollByY(50);
    return false;
  });

  // $('.filters__hide').click(function () {
  //   $('.side-nav-close.white-nav-close').trigger('click');
  //   toggleMapSearchResults();
  // });

  // toggleMapSearchResults();
  showArrows();

});

function showArrows() {
  var panelHeight = $('.jspPane').height();
  var containerHeight = $('.carto .map__search .container').height();

  if (panelHeight > containerHeight) {
    $('.map__search__arrow').addClass('map__search__arrow--visible');
    $('.carto .map__search ul').removeClass('no-margin');

  } else {
    $('.map__search__arrow').removeClass('map__search__arrow--visible');
    $('.carto .map__search ul').addClass('no-margin');
  }
}

// function toggleMapSearchResults() {
//   var map = $('.map-content');
//   var btn = $('.filters__hide');
//
//   if(map.hasClass('map__search--active')) {
//     btn.removeClass('filters__hide--hidden');
//   } else {
//     btn.addClass('filters__hide--hidden');
//   }
// }

$(window).resize(function () {
  $('.map__search .container').jScrollPane({
    reinitialize: true
  });

  showArrows();
});
