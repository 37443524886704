function autocomplete() {
    $('.js-combobox-1').accessibleCombobox();
    $('.js-combobox-2').jsonToDatalist({
        callback: function(obj) {
            obj.accessibleCombobox({
                comboboxPrefixClass: 'combobox',
                comboboxHelpText: 'Utilisez les touches de tabulation ou la flèche du bas pour naviguer dans la liste du champ autocomplété. Confirmez votre choix avec la touche entrée, ou la touche échap pour fermer la liste de suggestions.',
                defaultClassForInvisibleText: 'invisible',
                suggestionSingle: 'Il y a ',
                suggestionPlural: 'Il y a ',
                suggestionWord: 'suggestion',
                comboboxButtonTitle: 'Effacer ce champ',
                comboboxButtonClearText: 'X',
                comboboxCaseSensitive: 'no',
                comboboxMinLength: 0,
                comboboxLimitNumberSuggestions: 666,
                comboboxSearchOption: 'containing',
                comboboxSeeMoreText: 'Plus de résultats…'
            });
        }
    });
    $('.js-combobox-3').jsonToDatalist({
        jsonPath: 'typo3conf/ext/stratis_site/Resources/Public/JavaScript/lib/fruits-sample.json',
        callback: function(obj) {
            obj.accessibleCombobox();
        }
    });
}

