function enableDatePicker() {
    var inputDatepicker = $('input[type=text].datepicker');
    var inputDate = $('input[type=date]:not(.datepicker)');

    // Input date is supported ?
    var hasNativeDatePicker = function () {
        var elem = document.createElement('input');

        elem.setAttribute('type', 'date');
        elem.value = 'foo';

        return (elem.type === 'date' && elem.value !== 'foo');
    };

    function frenchToEnglishDate(frenchDate, separatorIn, separatorOut) {
        var dateElem = frenchDate.split(separatorIn);
        return dateElem[2] + separatorOut + dateElem[1] + separatorOut + dateElem[0];
    }

    function showInternationalDate(argObj) {
        var argObjId = argObj.id || argObj.attr('id'),
            argObjDate = argObj.date || new Date(frenchToEnglishDate(argObj.val()), '-', '/'),
            inputHidden = document.getElementById(argObjId + '-hidden');

        inputHidden.value = datePickerController.printFormattedDate(argObjDate, "%Y-%m-%d", false);
    }

    function pluginDatepicker($this) {
        var thisId = $this.attr('id'),
            thisName = $this.attr('name'),
            obj = {};

        $this
            .attr('data-name',thisName)
            .removeAttr('name')
            .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="">')
            .on("change", function () {
                var inputHidden = $this.nextAll('#'+thisId+'-hidden');
                var formatDate = frenchToEnglishDate($this.val(), '/', '-');
                inputHidden.val(formatDate);
            });

        if ($this.val() !== '') {
            showInternationalDate($this);
        }

        obj[thisId] = "%d/%m/%Y";
        datePickerController.createDatePicker({
            formElements:      obj,
            callbackFunctions: {
                "datereturned": [showInternationalDate]
            }
        });

    }

    function nativeDatepicker($this) {
        $this.each(function () {
            var thisId = $this.attr('id'),
                thisValue = $this.val() || '',
                thisName = $this.attr('name');

            $this
                .attr('data-name',thisName)
                .removeAttr('name')
                .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="' + thisValue + '">')
                .on("change", function () {
                    var inputHidden = $this.next();
                    inputHidden.val($this.val());
                });
        });
    }

    // If has no native datepicker (IE11)
    if (!hasNativeDatePicker()) {
        inputDatepicker.each(function() {
            pluginDatepicker($(this));
        });
        inputDate.each(function() {
            pluginDatepicker($(this));
        });
    }
    // If has native datepicker (Chrome, Firefox, Opera, Safari)
    else {
        inputDatepicker.each(function() {
            pluginDatepicker($(this));
        });
        inputDate.each(function() {
            nativeDatepicker($(this));
        });
    }
}


function enableTimePicker(){
    $('.time input').timepicker({
        'timeFormat': 'H:i'
    });
}

