function customTabs() {
    $(".offer-tabs__btn-wrapper a").click(function (event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("href");
        $(".offer-tabs__item").not(tab).css("display", "none");
        $(tab).fadeIn();
    });
}
